:root {
    --text_color: red;
    --secondary_color: #00D8FF;
}
#markdown_container {
    background-color: white;
    padding: 0 .5rem;
}
/* h1 elements */
#markdown_container > h1 {
    font-size: 1.8rem;
    font-weight: 800;
}
#markdown_container * {
    word-wrap: break-word
}
#markdown_container img {
    width: 100%;
    max-width: 15rem;
}
/* code block style */
#markdown_container > pre {
    background-color: #141414;
    color: var(--secondary_color);
    font-weight: 600;
    padding: 1rem .5rem;
    border-radius: 4px;
    width: 100%;
    overflow-x: auto;
}