/* ! keyframes for alert animations */
/* opening navbar */
@-webkit-keyframes mobile-nav-out {
    0% {
        transform: translateX(-20rem)
    }
    100% {
        transform: translateX(0);
    }
}

@-moz-keyframes mobile-nav-out {
    0% {
        transform: translateX(-20rem)
    }
    100% {
        transform: translateX(0);
    }
}

@-ms-keyframes mobile-nav-out {
    0% {
        transform: translateX(-20rem)
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes mobile-nav-out {
    0% {
        transform: translateX(-20rem)
    }
    100% {
        transform: translateX(0);
    }
}