#tooltip {
    -webkit-animation: fade .4s ease;
    -moz-animation: fade .4s ease;
    -o-animation: fade .4s ease;
    animation: fade .4s ease;
}

#tooltip:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-top: solid 10px rgba(19, 19, 19, 0.8);
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}

@-webkit-keyframes fade {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@-moz-keyframes fade {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@-ms-keyframes fade {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@-o-keyframes fade {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fade {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}