.passwordInputStyles_wrap {
    position: relative;
    width: 100%;
}
.passwordInputStyles_input {
    width: 100%;
    border: 1px solid white;
    padding: .3rem;
    font-size: 1rem;
    color: black;
    outline: none;
    background-color: white;
}

/* style of password icons */

.passwordInputStyles_icon {
    position: absolute;
    right: .5rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 1.8rem;
    cursor: pointer;
}