/* ! keyframes for alert animations */

@-webkit-keyframes alert-in {
    0% {
        transform: translateY(-20rem)
    }
    15% {
        transform: translateY(0);
    }
    70% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(2rem);
    }
    100% {
        transform: translateY(-50rem);
    }
}

@-moz-keyframes alert-in {
    0% {
        transform: translateY(-20rem)
    }
    15% {
        transform: translateY(0);
    }
    70% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(2rem);
    }
    100% {
        transform: translateY(-50rem);
    }
}

@-ms-keyframes alert-in {
    0% {
        transform: translateY(-20rem)
    }
    15% {
        transform: translateY(0);
    }
    70% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(2rem);
    }
    100% {
        transform: translateY(-50rem);
    }
}

@keyframes alert-in {
    0% {
        transform: translateY(-20rem)
    }
    15% {
        transform: translateY(0);
    }
    70% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(2rem);
    }
    100% {
        transform: translateY(-50rem);
    }
}